import React from 'react';
import LayoutCss from './layout.module.css'
import { Row, Container, Col } from 'react-bootstrap';
import PdfViewer from '../pdfViewer'
import Initials from '../signature_types/initials'
import { useTranslation } from 'react-i18next';

export default function Layout({
  document,
  signature,
  params
}) {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Row className={LayoutCss.pdf}>
        <Col md={6} className={LayoutCss.header}>
          <Row className="justify-content-md-end">
            <Col lg={7}>
              <Col xs={12} className={LayoutCss.logo}>
                <img src={document.logo} alt="Logo" width="150"/>
              </Col>
              <PdfViewer url={document.document_url} height={600}/>
            </Col>
          </Row>
        </Col>
        <Col md={6} className={LayoutCss.right}>
          <Row className="justify-content-md-start">
            <Col lg={6}>
              <Col xs={12} className={LayoutCss.help}>
              <span className={LayoutCss.trouble}>{t('initials.having_trouble')}</span> <span className={LayoutCss.gethelp}>{t('initials.get_help')}</span>
              </Col>
              <Initials signature={signature} document={document} params={params} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}