import i18n from 'i18next';
import {initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "welcome": "Welcome",
          "initials": {
            "welcome": "Welcome",
            "input": "Please enter your initials",
            "place_holder": "Enter initials",
            "instructions": "Enter your initials without spaces",
            "terms_and_conditions": "I accept the terms and conditions",
            "send": "Send",
            "having_trouble": "having trouble?",
            "get_help": "get help",
            "not_match": "The initials entered do not match, please try again",
            "not_found": "Sorry, the document you are trying to access was not found..",
            "person_responsible": "The patient is a minor, please enter the initials of the person responsible."
          },
        
          "error_message": "An error has occurred, please try again later."
        }
      },
      es: {
        translation: {
          "welcome": "Bienvenido",
          "initials": {
            "welcome": "Bienvenido",
            "input": "Por favor ingresa tus iniciales",
            "place_holder": "Ingresa las iniciales",
            "instructions": "Ingresa tus iniciales sin espacios",
            "terms_and_conditions": "Acepto los términos y condiciones",
            "send": "Enviar",
            "having_trouble": "¿Teniendo problemas?",
            "get_help": "Consigue ayuda",
            "not_match": "Las iniciales ingresadas no coinciden, por favor intente nuevamente",
            "not_found": "Lo sentimos, el documento al que intenta acceder no fue encontrado.",
            "person_responsible": "El paciente es menor de edad, por favor ingresa las iniciales del responsable."
          },
        
          "error_message": "Ha ocurrido un error, por favor intente nuevamente mas tarde."
        }
      }
    },
    lng: 'es',
    fallbackLng: 'es',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
