import React, { useState, useEffect } from 'react';
import { getDocumentData } from '../services/documents'
import PdfLayout from '../components/pdf_layout/layout'
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../components/errorPage'

export default function Document({match}) {
  const { t } = useTranslation();
  let history = useHistory();
  const [response, setResponse] = useState({});

  async function componentDidMount() {
    let query = queryString.parse(history.location.search);
    let slug = match.params.slug;

    let response = await getDocumentData({slug, id: query.id, redirect: query.redirect})
    setResponse(response)
  }

  useEffect(() => {
    document.title = t('welcome');
    componentDidMount();
    // eslint-disable-next-line
  }, []);

  if (response.error && response.error.response.status) {
    return (<ErrorPage error={ response.error }/>)
  } else if(!response.document) {
    return (<div></div>);
  }

  return (
    <PdfLayout document={response.document} signature={response.signature} params={response.params}>
    </PdfLayout>
  )
}