function ENV() {
  let ENV = {
    apiUrl: '',
    apiPath: 'api/v1',
    patientScope: ''
  };

  if(process.env.REACT_APP_STAGE === 'development') {
    ENV.apiUrl = 'http://localhost:4000';
  }

  if(process.env.REACT_APP_STAGE === 'staging') {
    ENV.apiUrl = 'https://nimbox-digital-signature-stgng.herokuapp.com';
  }

  if(process.env.REACT_APP_STAGE === 'production') {
    ENV.apiUrl = 'https://nimbox-digital-signature-stgng.herokuapp.com';
  }
  
  ENV.patientScope = `${ENV.apiUrl}/${ENV.apiPath}`;

  return ENV;
};

export default ENV();