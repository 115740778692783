import React from 'react';
import style from './errorPage.css'
import { Row, Container, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function ErrorPage({error}) {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col className="col-md-12">
        <div className="error align-middle">
          <h1>
            <span className="status">
              {(error && error.response.status)}
            </span>
          </h1>
          <h3>{t('initials.not_found')}</h3>
        </div>
        </Col>
      </Row>
    </Container>
  )
}