import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './i18n';
import './App.css';
import { AuthContext } from "./context/auth";
import {isMobile} from 'react-device-detect';
import { useMediaQuery } from 'react-responsive'
import Flash from './components/Flash';
import signature from "./pages/signature.js";
import Bus from './Utils/Bus';

function App() {
  const existingToken = localStorage.getItem("token");
  const [authToken, setAuthToken] = useState(existingToken);
  
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
 

  const setToken = (token) => {
    localStorage.setItem("token", token);
    setAuthToken(token);
  }
  if(isMobile || isTabletOrMobile) {
    // return (
    //   <Appstore></Appstore>
    // );
    
  }

  window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));


  return (
    <AuthContext.Provider>
      <Router>
      {/* <Flash /> */}
      <Switch>
        <Route exact path="/d/:slug" component={signature} />
      </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;

