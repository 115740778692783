export function getParams(params) {
  const urlParams = new URLSearchParams(params);
  const entries = urlParams.entries();
  return paramsToObject(entries);
}

export function base64Decode(data) {
  return Buffer.from(data, 'base64').toString('binary')
}


function paramsToObject(entries) {
  const result = {}
  for(const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}