import { Row, Container, Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { sign } from "../../services/signatures"
import { ErrorMessage } from '@hookform/error-message';
import style from './initials.module.css'
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

export default function Initials({
  signature,
  document,
  params
}) {
  const { t } = useTranslation();
  let [termsAccepted, setTermsAccepted] = useState(false);

  const { register, handleSubmit, setError, formState: { errors } } = useForm();

  const onSubmit =(async (formData) => {
    let response = await sign({ signature, document, formData, params })

    if(response && response.error){
      if(response.error.response.status === 422) {
        setError("txtInitials", {
          type: "manual",
          message: t('initials.not_match'),
        })
      } else if(response.error.response.status !== 200) {
        setError("txtInitials", {
          type: "manual",
          message: t('error_message'),
        })
      }
    }
  })

  function handleTermns(e){
    setTermsAccepted(e.target.checked)
  }

  return (
    <Container>
      <Row>
        <Form onSubmit={handleSubmit(onSubmit)} className="w-100">
          <h4>{t('initials.welcome')} { signature.full_name }</h4>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              {signature.is_under_age ? t('initials.person_responsible') : t('initials.input')}
            </Form.Label>

            <Form.Control 
              type="text"
              name="txtInitials"
              placeholder={t('initials.place_holder')} 
              {...register('txtInitials', { required: t('initials.input') })}
            />
            <ErrorMessage
              errors={errors}
              name="txtInitials"
              render={({ message }) => <p className={style.labelError}>{message}</p>}
            />

            <Form.Text className="text-muted">
              {t('initials.instructions')}
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <div className={style.terms_and_conditions}>
              <Form.Check type="checkbox" label="" onChange={e => handleTermns(e)} />
              <a href={document.configuration.terms_and_conditions} target="_blank" rel="noopener noreferrer">
                {t('initials.terms_and_conditions')}
              </a>
            </div>
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="primary" type="submit" size="lg" className={style.btn} name="btnSend" disabled={!termsAccepted}>
              {t('initials.send')}
            </Button>
          </div>
        </Form>
      </Row>
    </Container>
  )
}