import { get, patch } from '../utilities/rest.js'

const PATH = 'signatures'

export async function findSignature(id) {
  return await get(`${PATH}/${id}`)
}

export async function sign(data) {
  const response = await patch(
    `${PATH}/${data.params.id}/sign`, 
    buildSignature(data.formData)
  )

  if(!response.error) {
    redirect(data.document, data.signature, data.params)
  }else{
    return response
  }
}

function buildSignature(formData) {
  return {
    signature: {
      metadata: {
        initial_entered: formData.txtInitials
      }
    }
  }
}

function redirect(document, signature, params) {
  if(params.redirect) {
    window.location.href = params.redirect;
  }else if(document.configuration.redirect) {
    window.location.href = `${document.configuration.redirect}?id=${signature.id}&origin=${signature.origin}`; 
  }
}