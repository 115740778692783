import { get } from '../utilities/rest.js'
import { base64Decode } from '../utilities/paramsToObject.js'
import { findSignature } from './signatures'

const PATH = 'documents'

export async function getDocumentData(query) {
  let response = {}
  let document = await get(`${PATH}?slug=${query.slug}`)

  if(!document.error){
    let signatures = await findSignature(query.id)

    if(!signatures.error){
      response.signature = signatures.data
      response.document = document.data
      response.params = {
        id: query.id,
        redirect: query.redirect ? base64Decode(query.redirect) : null
      }
    }else{
      response.error = signatures.error
      response.status = signatures.status
    }
  }

  return {
    query,
    ...response
  }
}

