import axios from "axios";
import ENV from '../environment';
const HOST = `${ENV.patientScope}/`
const token = ''

export async function get(url, customURL = false) {
  return await request({
    url,
    method: 'get',
    body: null,
    customURL
  })
}

export async function post(url, body, customURL = false) {
  return await request(
    {
      url,
      method: 'post',
      body,
      customURL
    }
  )
}

export async function put(url, body, customURL = false) {
  return await request({
    url,
    method: 'put',
    body,
    customURL
  })
}

export async function patch(url, body, customURL = false) {
  return await request({
    url,
    method: 'patch',
    body,
    customURL
  })
}

async function request(params) {
  let endpoint = buildEndpoint(params)

  return await axios(endpoint, {
    method: params.method,
    data: params.body ? JSON.stringify(params.body) : null,
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }).then((response) => {
    return checkStatus(response)
  }).catch((error)=> {
    return formatResponse(null, error)
  })
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return formatResponse(response)
  } else {
    var error = {
      message: response.statusText,
      code: response.status
    }
    
    return formatResponse(null, error)
  }
}

function formatResponse(response = null, error = null) {
  return {
    data: response ? response.data : null,
    error,
    status: response ? response.status : error.response.status
  }
}

function buildEndpoint(params){
  if(params.customURL) {
    return params.url
  } else {
    return `${HOST}${params.url}`
  }
}